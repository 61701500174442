import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import { PARTNER_KEITARO_ROUTE, SIGN_UP_ROUTE } from '../../const/routes.const';
import { KEITARO_DISCOUNT, KEITARO_PROMO_CODE, KEITARO_NAME } from '../../const/parnters.const';
import PartnerLayout from '../../components/Partners/PartnerLayout';
import keitaro_mobile from '../../assets/images/partners/keitaro_mobile.png';
import keitaro_mobile2x from '../../assets/images/partners/keitaro_mobile_2x.png';
import keitaro_desktop from '../../assets/images/partners/keitaro_desktop.png';
import keitaro_desktop2x from '../../assets/images/partners/keitaro_desktop_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlueSpan from '../../components/common/typography/BlueSpan';
import PartnerDiscount from '../../components/Partners/PartnerDiscount';
import LinkWrapper from '../../components/common/LinkWrapper';

const KeitaroPage = (): React.ReactElement => (
  <PartnerLayout
    description={
      <>
        <p>
          <Trans>
            Keitaro Tracker is an advertising tracker specifically designed for media buying and
            internet marketing. It is one of the most well-known trackers in the market, used as a
            primary tool for optimizing, controlling, managing, and protecting advertising
            campaigns. By using Keitaro, you can reduce expenses on unprofitable combinations and
            focus on increasing profits.
          </Trans>
        </p>
        <h3>
          <Trans i18nKey="Offer for Octo Browser users">
            Offer for <BlueSpan>Octo Browser users</BlueSpan>
          </Trans>
        </h3>
        <p>
          <Trans i18nKey="This offer is valid for Octo Browser users only. Create an account to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and claim your offer.">
            This offer is valid for Octo Browser users only. <LinkWrapper url={SIGN_UP_ROUTE}>Create an account</LinkWrapper> to claim your discount.
            If you already use Octo Browser, go to the partner's website, sign up, and claim your
          </Trans>
        </p>
        <PartnerDiscount
          description={
            <Trans
              i18nKey="partner.keitaro.promo.code.discount"
              tOptions={{
                discount: KEITARO_DISCOUNT,
                promoCode: KEITARO_PROMO_CODE,
              }}
            >
              <strong>{KEITARO_PROMO_CODE}</strong>.
            </Trans>
          }
          discount={KEITARO_DISCOUNT}
          href={PARTNER_KEITARO_ROUTE}
        />
        <h3>
          <Trans>Key features of the Keitaro Tracker:</Trans>
        </h3>
        <ul>
          <li>
            <Trans>High-performance reports with 30+ built-in metrics, updated every minute</Trans>
          </li>
          <li>
            <Trans>Keitaro Geo DB</Trans>
          </li>
          <li>
            <Trans>Click API/Admin API</Trans>
          </li>
          <li>
            <Trans>Local landing pages and built-in editor</Trans>
          </li>
          <li>
            <Trans>Integrations with Facebook, TikTok, Cloudflare, Namecheap, and more</Trans>
          </li>
          <li>
            <Trans>Full IPV6 support</Trans>
          </li>
          <li>
            <Trans>User activity log</Trans>
          </li>
          <li>
            <Trans>Admin panel and landing page protection from scanning by spy services</Trans>
          </li>
          <li>
            <Trans>Traffic stream monitoring</Trans>
          </li>
          <li>
            <Trans>Online support</Trans>
          </li>
        </ul>
        <p><Trans>In addition, Keitaro offers over 250 ready-made CPA network templates and more than 200 traffic source templates, which include a set of all necessary metrics, Postback URL links and conversion params. This allows you to start working with your favorite resources in just a few clicks.</Trans></p>
        <p><Trans>Also, for all new clients, a 14-day trial is available, in which you will receive an Expert license with no limitations in functionality.</Trans></p>
      </>
    }
    href={PARTNER_KEITARO_ROUTE}
    imgDesktop={<ImageContainer src={keitaro_desktop} srcSet={{ _2x: keitaro_desktop2x }} />}
    imgMobile={<ImageContainer src={keitaro_mobile} srcSet={{ _2x: keitaro_mobile2x }} />}
    title={KEITARO_NAME}
  />
);

export default KeitaroPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["keitaroPage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
